import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { SwiperModule } from 'swiper/angular';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {TabsPageModule} from "./tabs/tabs.module";
import {HttpConfigInterceptor} from "./services/interceptor.interceptor";
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent],
    imports: [BrowserModule,
      IonicModule.forRoot(),
      AppRoutingModule,
      SwiperModule,
      TabsPageModule,
      HttpClientModule,
      BrowserAnimationsModule,
      MatSnackBarModule,
      FormsModule
    ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
