import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'autolettura',
    loadChildren: () => import('./pages/autolettura/autolettura.module').then(m => m.AutoletturaPageModule)
  },
  {
    path: 'contatore',
    loadChildren: () => import('./pages/contatore/contatore.module').then(m => m.ContatorePageModule)
  },
  {
    path: 'convenzioni',
    loadChildren: () => import('./pages/convenzioni/convenzioni.module').then(m => m.ConvenzioniPageModule)
  },
  {
    path: 'attivita' + '/:id',
    loadChildren: () => import('./pages/attivita/attivita.module').then( m => m.AttivitaPageModule)
  },
  {
    path: 'autolettura_luce',
    loadChildren: () => import('./pages/autolettura-luce/autolettura-luce.module').then( m => m.AutoletturaLucePageModule)
  },
  {
    path: 'pagamenti',
    loadChildren: () => import('./pages/pagamenti/pagamenti.module').then( m => m.PagamentiPageModule)
  },
  {
    path: 'contatti',
    loadChildren: () => import('./pages/contatti/contatti.module').then( m => m.ContattiPageModule)
  },
  {
    path: 'prodotti',
    loadChildren: () => import('./pages/prodotti/prodotti.module').then( m => m.ProdottiPageModule)
  },
  {
    path: 'prodotto' + '/:id',
    loadChildren: () => import('./pages/prodotto/prodotto.module').then( m => m.ProdottoPageModule)
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
