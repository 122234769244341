import { Injectable } from '@angular/core';
import {ApiRoutes} from "../common/api-routes";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {error} from "protractor";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  errors = []
  base_img_url = "https://dashboard.zeloenergia.com//storage/app/"

  baseUrl = "https://dashboard.zeloenergia.com/public/api/"

  constructor(private httpClient: HttpClient) { }

  get(to: ApiRoutes, callback: any) {
    this.httpClient.get(this.baseUrl + to).subscribe((res: any) => {
      callback(res)
    })
  }

  post(to: ApiRoutes, data: any, callback: any) {
    var options = {
      //
    }
    this.httpClient.post(this.baseUrl + to, data, options).subscribe(res => {
      callback(res)
    })
  }

  upload(to: ApiRoutes, tipo: string, m_cubi_kwh: string, autolettura: string, file?: File): Observable<any> {
    const formData = new FormData()

    errors: [] = [];

    formData.append("tipo", tipo)
    if(tipo == 'pdr') {
      formData.append("m_cubi", m_cubi_kwh)
    }
    else if(tipo == 'pod') {
      formData.append("kwh", m_cubi_kwh)
    }
    formData.append("autolettura", autolettura)

    if(file) {
      formData.append("file", file, file.name)
    }

    return this.httpClient.post(this.baseUrl + to, formData)

  }

  getById(to: ApiRoutes, id: string, callback: any) {
    this.httpClient.get(this.baseUrl + to + '/' + id, ).subscribe((res: any) => {
      callback(res)
    })
  }

}
